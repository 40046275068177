<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=shallShowActiveChatContactSidebar=shallShowUserProfileSidebar=false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat.contact"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
          @click="startConversation"
        >
          Start Conversation
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>
              <h6 class="mb-0">
                {{ activeChat.contact.name }}
              </h6>
            </div>

            <!-- Contact Actions -->
            <div class="d-flex align-items-center">
              <feather-icon
                icon="PhoneCallIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="VideoIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="SearchIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
              />
              <div class="dropdown">
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item>
                    View Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Mute Notifications
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Block Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Clear Chat
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Report
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </header>
        </div>
        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            :chat-data="activeChat"
            :profile-user-avatar="profileUserDataMinimal.avatar"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Enter your message"
            />
          </b-input-group>
          <b-button
            variant="primary"
            type="submit"
          >
            Send
          </b-button>
        </b-form>
      </div>
    </section>
    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeChat.contact || {}"
    />
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :contacts="contacts"
        :active-chat-contact-id="activeChat.contact ? activeChat.contact.id : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact2"
      />
    </portal>
  </div>
</template>

<script>

import store from '@/store'
import {
  ref, nextTick,
} from '@vue/composition-api'
import {
  BAvatar, BDropdown, BDropdownItem, BForm, BInputGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import Echo from 'laravel-echo'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'
window.io = require('socket.io-client')

export default {
  methods: {
    openChatOfContact2(id) {
      // sự kiện rời phòng
      if (this.hereRoomId !== id) {
        console.log('leaveChannel ', this.echo.leaveChannel(`chat.${this.hereRoomId}`))
      }
      this.hereRoomId = id
      const findIndex = this.contacts.findIndex(item => item.id === id)
      console.log('openChatOfContact2 id', id, findIndex)
      // get noi dung tin nhắn
      this.$http.get(`http://laravel-chat.com/api/conversations/${id}`)
        .then(({ data }) => {
          const contacts = this.contacts[findIndex]
          this.echo.channel(`chat.${this.hereRoomId}`)
            .listen('MessageSent', e => {
              if (e.user.id === this.hereRoomId) {
                // cùng phòng
                this.activeChat.chat.chat.push({
                  type: 'user',
                  message: e.message,
                  time: Date.now(),
                  senderId: e.id, // id người gửi
                })
                contacts.last_msg = e.message//last msg
              }
              nextTick(() => { this.scrollToBottomInChatLog() })
              console.log('begin-chat e', this.echo.socketId(), e)
            })
          contacts.unread = 0
          const dataC = data.data.map((item) => {
            return {
              senderId: item.user_id+"",
              message: item.message,
              time: item.created_at,
              type: item.user_id_sender === null ? 'user' : 'admin'
            }
          }).reverse()
          console.log('dataC', dataC)
          this.activeChat = {
            contact: contacts,
            chat: {
              chat: dataC,
            },
          }
          // Scroll to bottom
          nextTick(() => { this.scrollToBottomInChatLog() })
        })
      // response.data
      // {
      //                   message: 'Hi',
      //                   time: 'Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)',
      //                   senderId: 112,
      //                 },
      // Set unseenMsgs to 0
      // const contact = chatsContacts.value.find(c => c.id === userId)
      // if (contact) contact.chat.unseenMsgs = 0

      
    },
    sendMessage() {
      if (!this.chatInputMessage) return
      const payload = {
        contactId: this.activeChat.contact.id,
        // eslint-disable-next-line no-use-before-define
        senderId: this.profileUserDataMinimal.id,
        message: this.chatInputMessage,
      }
      // gửi tin đi để lấy id và gửi broadcast
      this.$http.post(`http://laravel-chat.com/api/conversations/${this.idAdmin}`, payload)
        .then(({ data }) => {
          if (data.success) {
            const { newMessageData, chat } = {
              newMessageData: {
                type: 'admin',
                message: data.data.message,
                time: Date.now(),
                senderId: data.data.user_id_sender, // id người gửi
              },
            }
            if (chat !== undefined) {
              this.activeChat = { chat, contact: this.activeChat.contact }

              this.contacts.push({
                ...this.activeChat.contact,
                chat: {
                  id: chat.id,
                  lastMessage: newMessageData,
                  unseenMsgs: 0,
                },
              })
            } else {
              // Add message to log
              this.activeChat.chat.chat.push(newMessageData)
            }

            // Scroll to bottom
            nextTick(() => { this.scrollToBottomInChatLog() })
          } else {
            // toast
          }
        })
      // ? If it's not undefined => New chat is created (Contact is not in list of chats)
      // Reset send message input value
      this.chatInputMessage = ''

      // Set Last Message for active contact
      // const contact = chatsContacts.value.find(c => c.id === activeChat.contact.id)
      // contact.chat.lastMessage = newMessageData

    },
    scrollToBottomInChatLog() {
      const scrollEl = this.$refs.refChatLogPS.$el
      scrollEl.scrollTop = scrollEl.scrollHeight
    },
    showUserProfileSidebar() {
      // store.dispatch('app-chat/getProfileUser')
      //   .then(response => {
      //     profileUserData.value = response.data
      //     shallShowUserProfileSidebar.value = true
      //   })
    },
  },
  data() {
    return {
      idAdmin: 1,
      hereRoomId: 0,
      echo: null,
      chatsContacts: [],
      contacts: [],
      chatInputMessage: '',
      activeChat: {},
      refChatLogPS: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      mqShallShowLeftSidebar: false,
      profileUserData: {},
      profileUserDataMinimal: {},
      shallShowUserProfileSidebar: false,
      shallShowActiveChatContactSidebar: false,
    }
  },
  created() {
    this.$http.get('http://laravel-chat.com/api/users_list')
      .then(({ data}) => {
        this.contacts = data.data
        this.$set(this, 'contacts', data.data)
        console.log('data', data)
      })
  },
  mounted() {
    console.log('mounted')

    this.echo = new Echo({
      broadcaster: "socket.io",
      host: 'laravel-chat.com:6001',
    })
    console.log('this.echo', this.echo)

    // lắng nghe tin nhắn đến cùng phòng
      
    this.echo.channel('usersList')
      .listen('ChannelSent', (e) => {
        // push vào danh sách
        this.contacts = [e.user, ...this.contacts]
        console.log('usersList e', this.echo.socketId(), e)
      })
      .listen('RecentMsgSent', e => {
        // đẩy user có tin mới lên đầu,
        console.log('RecentMsgSent e', e)
        // const findIndex = this.contacts.findIndex(item => item.id === e.user.id)
        // if (findIndex !== '') {
        //   this.contacts.splice(findIndex, 1)[0]
        //   this.contacts.splice(0, 0, this.contacts[findIndex])
        // }
      })
  },
  components: {

    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  setup() {

    const { resolveAvatarBadgeVariant } = useChat()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    // const chatsContacts = ref([])
    // const contacts = ref([])

    // const fetchChatAndContacts = () => {

    //   store.dispatch('app-chat/fetchChatsAndContacts')
    //     .then(response => {
    //       chatsContacts.value = response.data.chatsContacts
    //       contacts.value = response.data.contacts
    //       // eslint-disable-next-line no-use-before-define
    //       profileUserDataMinimal.value = response.data.profileUser
    //     })
    // }

    // fetchChatAndContacts()

    // ------------------------------------------------
    // Single Chat
    // --------------------------------------
      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
     


    // Active Chat Contact Details

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      // chatsContacts,
      // contacts,

      // Single Chat
      refChatLogPS,
      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "@core/scss/base/pages/app-chat.scss";
@import "@core/scss/base/pages/app-chat-list.scss";
</style>
