<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ user.name }}
      </h5>
      <p class="card-text text-truncate">
        {{ user.last_msg }}
      </p>
    </div>
    <div
      class="chat-meta text-nowrap"
    >
      <small class="float-right mb-25 chat-time">{{ formatDateToMonthShort(user.created_at, { hour: 'numeric', minute: 'numeric' }) }}</small>
      <b-badge
        v-if="user.unread"
        pill
        variant="primary"
        class="float-right"
      >
        {{ user.unread }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'
import useChat from './useChat'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat()
    return { formatDateToMonthShort, resolveAvatarBadgeVariant }
  },
}
</script>

<style>

</style>
